<template>
  <div class="StatusPageFooter">
    <div class="links-wrapper">
      <div v-if="this.$route.path.includes('history')">
        <a class="incident-history" @click="goBack">← {{ textGoBack }}</a>
      </div>
      <div v-else>
        <router-link class="incident-history" :to="{ name: 'status.history' } ">
          <span class="optional">{{ textShowIncidentHistory }}</span>
        </router-link>
      </div>
      <div class="app-name" v-if="!statusPage.remove_branding">
        Powered by <a :href="url">{{ appName }}</a>
      </div>
    </div>
<!--    <div v-if="statusPage.subscribe_to_updates">-->
<!--      <a :href="'//' + domainName + '/subscriptions'" class="incident-history">Manage your subscriptions -></a>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {

  props: {
    statusPage: {
      required: true,
      type: Object
    },
    statusPageLanguage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      appName: process.env.VUE_APP_BRAND_NAME,
      domainName: process.env.VUE_APP_CORE_DOMAIN,
      url: String
    }
  },

  created () {
    this.url = '//' + process.env.VUE_APP_LANDING_DOMAIN + '/?utm_source=' + this.statusPage.slug + '&utm_medium=userstatuspage&utm_campaign=statuspage'
  },

  methods: {
    goBack () {
      return this.$router.go(-1)
    }
  },

  computed: {
    textShowIncidentHistory () {
      if (this.statusPageLanguage.text_show_incident_history) {
        return this.statusPageLanguage.text_show_incident_history
      }

      return 'Show Incident History →'
    },

    textGoBack () {
      return this.statusPageLanguage.text_go_back ?? 'Go Back'
    }

  }
}
</script>

<style lang="scss" scoped>
  .StatusPageFooter {
    padding: 50px 0;

    .incident-history,
    .app-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.4;

      @media (max-width: 500px) {
        .incident-history .optional {
          display: none;
        }
      }
    }

    .subscribe {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      padding: 2rem;
      border-radius: 7px;
    }

    .manage {
      position: relative;
      bottom: -.5rem;
      height: 0px;
    }

    .subscribe-error {
      width: 50%;
      padding: .5rem;
      margin: 1rem auto;
      text-align: center;
    }

    a.incident-history {
      cursor: pointer;

      &:hover {
        color: #219653;
      }
    }

    .app-name {
      text-align: right;

      a {
        color: #219653;
        font-weight: 500;
      }
    }
  }

  .links-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 500px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .app-name {
      @media (max-width: 500px) {
      margin-top: 10px;
      }
    }
  }
</style>
