<template>
  <div class="StatusPageNotFound">
    <div class="text">
      <div class="title">Status Page Not Found</div>
      <div class="description">
        <p class="mb-2">
          For some reason the url for this status page is not correct.
          Please, check if it is correct. In case of any problems we will be glad to solve your issue.
        </p>
        <p class="mb-4" v-if="supportEmail">
          Just drop a line to {{ supportEmail }}
        </p>
        <p>
          <base-button color="primary"
                       @click="goToHomePage">Return to your account</base-button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL
    }
  },

  methods: {
    goToHomePage () {
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .StatusPageNotFound {
    display: flex;
    justify-content: center;

    height: 100vh;
    width: 100vw;

    background-color: #f8f8f8;

    .text {
      margin-top: 100px;
      max-width: 480px;

      .title {
        font-size: 32px;
        margin-bottom: 10px;
      }

      .description {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
</style>
