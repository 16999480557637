<template>
  <div class="StatusPage">
    <status-page-header
      :status-page="statusPage"
      :status-page-language="statusPageLanguage"
      :logoText="logoText"
      :logo="logo"
      :headerImage="headerImage"
      :isDarkenHeaderImage="!!isDarkenHeaderImage"
      :logoStyles="logoStyles"
      :logoLink="logoLink"
    />
    <div class="container">
      <router-view
        :statusPage="statusPage"
        :status-page-language="statusPageLanguage"
      />

      <status-page-footer
        :status-page="statusPage"
        :status-page-language="statusPageLanguage"
      />
    </div>
  </div>
</template>

<script>
import StatusPageHeader from './StatusPageHeader.vue'
import StatusPageFooter from './StatusPageFooter.vue'

import statusPagesApi from '@/api/statusPagesApi.js'
import dayjs from 'dayjs'

export default {
  components: {
    StatusPageHeader,
    StatusPageFooter
  },

  props: {
    statusPage: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      monitors: [],
      logoText: null,
      logo: null,
      headerImage: null,
      favicon: null,
      isDarkenHeaderImage: false,
      logoStyles: null,
      logoLink: null,
      statusPageLanguage: {},

      since: dayjs().subtract(90, 'day').startOf('day'),
      till: dayjs().endOf('day')
    }
  },

  created () {
    // this.loadSnapshots()
    this.setStyles()

    if (this.statusPage && this.statusPage.status_pages_language) {
      this.statusPageLanguage = JSON.parse(this.statusPage.status_pages_language.json_data)
    }

    if (this.statusPageLanguage?.date_and_time_language?.type) {
      require(`dayjs/locale/${this.statusPageLanguage.date_and_time_language.type}`)
      dayjs.locale(this.statusPageLanguage.date_and_time_language.type)
    }
  },

  methods: {
    async setStyles () {
      let styles = {}
      let form = JSON.parse(localStorage.getItem('status_page_preview'))
      if (form && this.$route.query.preview && form.slug === this.statusPage.slug) {
        styles = {
          ...form,
          color_style: form.colorStyle,
          darken_header_image: form.darkenHeaderImage,
          font_style: form.fontStyle,
          header_image: form.headerImage,
          incidents_frame_style: form.incidentsFrameStyle,
          incidents_state_style: form.incidentsStateStyle,
          links_color_style: form.linksColorStyle,
          logo_sizes: form.logoSizes,
          status_frame_style: form.statusFrameStyle,
          status_text_style: form.statusTextStyle,
          text_style: form.textStyle,
          uptime_states_color_style: form.uptimeStatesColorStyle,
          uptime_style: form.uptimeStyle,
          uptime_text_style: form.uptimeTextStyle,
          custom_css: form.customCss,
          logo_link: form.logoLink,
          updates_button_style: form.updatesButtonStyle,
          status_icons_style: form.statusIconsStyle
        }
      } else {
        if (this.statusPage.status_pages_style) {
          for (let key in this.statusPage.status_pages_style) {
            if (
              (typeof this.statusPage.status_pages_style[key] === 'string' && this.statusPage.status_pages_style[key].includes('{"')) ||
              key === 'logo' || key === 'header_image' || key === 'custom_css' || key === 'logo_link' || key === 'favicon'
            ) {
              styles[key] = JSON.parse(this.statusPage.status_pages_style[key])
            } else {
              styles[key] = this.statusPage.status_pages_style[key]
            }
          }
        }
      }
      if (Object.keys(styles).length) {
        this.logoLink = styles.logo_link
        if (!styles.font_style.collapsed) {
          this.logoText = styles.font_style.logoText
        } else {
          this.logo = styles.logo
        }
        this.headerImage = styles.header_image
        this.favicon = styles.favicon
        this.isDarkenHeaderImage = styles.darken_header_image
        this.logoStyles = { maxWidth: styles.logo_sizes.width + 'px', maxHeight: styles.logo_sizes.height + 'px' }

        if (this.favicon) {
          var links = document.querySelectorAll('link[rel~=\'icon\']')
          for (let i = 0; i < links.length; i++) {
            links[i].href = this.favicon
          }
        }
      }
    },
    async loadSnapshots () {
      const data = await statusPagesApi.getSnapshots(this.statusPage.slug)

      let monitors = this.statusPage.monitors.slice()

      monitors = monitors.filter(monitor => {
        return data.find(i => i.monitor_id === monitor.id)
      }).map(monitor => ({
        ...monitor,
        snapshots: data.find(i => i.monitor_id === monitor.id).snapshots
      }))

      this.monitors = monitors
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .StatusPage {
    min-height: 100vh;
  }
  .container {
    max-width: 984px;
  }

  h1.uptime-title {
    font-size: 27px;
    margin-bottom: 20px;
    font-weight: 600;
    letter-spacing: -0.021em;
    margin-bottom: 40px;

    span {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 500;
      opacity: .6;
      letter-spacing: -0.017em;
    }
  }
</style>
